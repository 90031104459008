import { useEffect, useRef, useState } from 'react';

export enum DebounceChangeHandleResult {
  SUCCESS = 'SUCCESS',
  NOT_SUCCESS = 'NOT_SUCCESS',
}

export interface UseDebounceChangesProps<T> {
  form: T;
  excludeFields?: (keyof T)[];
  onChanges: (value: T) => DebounceChangeHandleResult | Promise<DebounceChangeHandleResult>;
}

export const useDebounceChanges = <T extends object>(props: UseDebounceChangesProps<T>) => {
  const { form, excludeFields, onChanges } = props;

  const prevFormRef = useRef(form);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const changedFieldsRef = useRef({});

  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);

  useEffect(() => {
    const prevForm = prevFormRef.current;

    const currentChangedFields: T = {} as T;
    for (const k in form) {
      const key = k as keyof T;
      if (form[key] !== prevForm[key]) {
        currentChangedFields[key] = form[key];
      }
    }

    if (Object.keys(currentChangedFields).length) {
      changedFieldsRef.current = {
        ...changedFieldsRef.current,
        ...currentChangedFields,
      };

      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }

      if (!Object.entries(prevForm).every(([k, v]) => excludeFields?.includes(k as keyof T) || !v)) {
        setIsUnsavedChanges(true);
        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(async () => {
          // onChanges(changedFieldsRef.current as T);
          // currently all fields are sent
          try {
            const result = await onChanges(form);
            if (result === DebounceChangeHandleResult.SUCCESS) {
              changedFieldsRef.current = {};
              setIsUnsavedChanges(false);
            }
          } catch (error) {
            console.error('Error on handling changes', error);
          } finally {
            debounceTimerRef.current = null;
          }
        }, 700);
      }
    }

    prevFormRef.current = form;
  }, [excludeFields, form, onChanges]);

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return isUnsavedChanges;
};
