import { Box, Button, Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmissionSigningStatus } from '../../../api';
import {
  useAbandonPreparationSigningMutation,
  useLazyGetSigningQuery,
  useSendSigningMutation,
} from '../../../api/signing';
import Card from '../../../components/card/Card';
import { DocumentsViewer } from '../../../components/documentsViewer/DocumentsViewer';
import { environment } from '../../../environments/environment';
import { useAppToast } from '../../../libs/ui/hooks';
import { useTypedSelector } from '../../../store';
import { SignersWidget } from './components/signers-widget';

export const SigningConfirmPage = () => {
  const { t } = useTranslation();
  const { showApiError } = useAppToast();
  const navigate = useNavigate();

  const { signingId } = useParams<{ signingId: string }>();
  const selectedSigning = useTypedSelector(state => state.signing.selectedSigning ?? undefined);
  const signing = signingId === selectedSigning?.id ? selectedSigning : undefined;

  const [fetchSigning, { isLoading }] = useLazyGetSigningQuery({
    pollingInterval: environment.filePollingIntervalSeconds * 1000,
  });

  const [sendSigning, { isLoading: isLoadingSend }] = useSendSigningMutation();
  const [abandonPreparationSigning, { isLoading: isLoadingAbandon }] = useAbandonPreparationSigningMutation();

  const load = useCallback(() => {
    fetchSigning({ signingId: signingId ?? '' });
  }, [fetchSigning, signingId]);

  useEffect(() => {
    load();
  }, [load]);

  const onSend = useCallback(async () => {
    if (!signingId) {
      throw new Error('Invalid application state! Signing ID is not defined');
    }

    try {
      await sendSigning({ id: signingId }).unwrap();
      navigate(`/signings/${signingId}`, { replace: true });
    } catch (error) {
      showApiError(error);
    }
  }, [navigate, sendSigning, showApiError, signingId]);

  const onAbandonPreparation = useCallback(async () => {
    if (!signingId) {
      throw new Error('Invalid application state! Signing ID is not defined');
    }

    try {
      await abandonPreparationSigning({ id: signingId }).unwrap();
      navigate(`/signings/${signingId}`, { replace: true });
    } catch (error) {
      showApiError(error);
    }
  }, [abandonPreparationSigning, navigate, showApiError, signingId]);

  useEffect(() => {
    if (signing?.submission?.status !== SubmissionSigningStatus.READY_TO_SEND) {
      navigate(`/signings/${signingId}`, { replace: true });
    }
  }, [navigate, signing?.submission?.status, signingId]);

  const currentUrl = signing?.documents?.[0]?.url;

  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');
  const disabledHoverBgColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.300');
  const disabledTextColor = useColorModeValue('navy.750', 'whiteAlpha.900');
  const disabledBgColor = useColorModeValue('secondaryGray.300', 'navy.700');

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }
  if (!signing) {
    return <Box>...{t('pages.signing.signingFetchError')}</Box>;
  }

  return (
    <Flex direction="column" gap="24px">
      <Card
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'auto', lg: 'hidden' }}
        position="static"
        display={'flex'}
        flexDirection="column"
        gap={'24px'}
      >
        <Grid gap="12px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
          <Card flexDirection={'column'} p="0">
            <Text fontSize="16px" fontWeight="bold" p="24px 24px 0 24px">
              {t('pages.signing.hereIsTheDocumentPreparedToSign')}
            </Text>
            <DocumentsViewer previewUrl={currentUrl} maxH="calc(100vh - 175px)" h="max(calc(100vh - 175px), 500px)" />
          </Card>
          <Card flexDirection={'column'} gap="16px" p="0">
            <Card flexDirection={'column'} p="0" flex="1">
              <Text fontSize="16px" fontWeight="bold" p="24px 24px 0 24px">
                {t('pages.signing.hereAreTheSignatoriesPleaseReviewAllTHeInformationBeforeProceeding')}
              </Text>
              <SignersWidget isInConfirmView signing={signing} flex="1" />
            </Card>

            <Flex
              gap="24px"
              justifyContent="end"
              direction={{ base: 'column', md: 'row' }}
              position="relative"
              p="24px"
            >
              <Button
                ps="40px"
                pe="40px"
                h="46px"
                fontSize="14px"
                borderRadius="100px"
                isDisabled={isLoadingAbandon || isLoadingSend}
                isLoading={isLoadingAbandon}
                color={disabledTextColor}
                bgColor={disabledBgColor}
                _hover={{ bgColor: disabledHoverBgColor }}
                onClick={onAbandonPreparation}
              >
                {t('pages.signing.backToEdit')}
              </Button>
              <Button
                colorScheme="brand"
                ps="40px"
                pe="40px"
                h="46px"
                fontSize="14px"
                borderRadius="100px"
                color={'white'}
                isDisabled={isLoadingAbandon || isLoadingSend}
                onClick={onSend}
                isLoading={isLoadingSend}
              >
                {t('pages.signing.proceedToSigning')}
              </Button>
            </Flex>
          </Card>
        </Grid>
      </Card>
    </Flex>
  );
};
