import {
  Box,
  Flex,
  Icon,
  Tab,
  Table,
  TabList,
  TabProps,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Signing, SigningListResponse } from '../../../../api/signing';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { formatDate } from '../../../../utils/date';
import {
  iconBySubmissionSigningStatus,
  iconColorBySigningStatus,
  iconColorSubmissionDraft,
  iconSubmissionDraft,
  signingStatusTranslateBySigningStatus,
  signingStatusTranslatedDraft,
} from '../variables/signing-status';
import { SubmissionSigningStatus } from '../../../../api';
import { getIconForSorting, TableHeader } from '../../../../libs/ui/table';

const columnHelper = createColumnHelper<SigningListResponse>();

export enum SigningTabKind {
  ALL = 'ALL',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  DECLINED = 'DECLINED',
}

const signingTabTranslateByKind: Record<SigningTabKind, string> = {
  [SigningTabKind.ALL]: 'pages.signings.tabs.all',
  [SigningTabKind.SENT]: 'pages.signings.tabs.sent',
  [SigningTabKind.SIGNED]: 'pages.signings.tabs.signed',
  [SigningTabKind.DECLINED]: 'pages.signings.tabs.declined',
};

export const signingTabKinds = Object.values(SigningTabKind);

export interface SigningTableProps {
  tabIndex: number;
  signings: SigningListResponse[];
  sorting: SortingState;
  onChangeTab: (index: number) => void;
  onSortingChange: OnChangeFn<SortingState>;
  isFetching?: boolean;
  fetchNextPage?: () => void;
  totalCount?: number;
  onClickSigning: (signing: Signing) => void;
}

export const SigningTable = ({
  tabIndex,
  signings,
  sorting,
  onChangeTab,
  onSortingChange,
  isFetching,
  fetchNextPage,
  totalCount,
  onClickSigning,
}: SigningTableProps) => {
  const { t } = useTranslation();

  const { colorMode } = useColorMode();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const rowHoverBg = useColorModeValue('secondaryGray.400', 'whiteAlpha.300');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const sidebarBg = useColorModeValue('white', 'navy.800');

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo(
    () => [
      columnHelper.accessor('job.name', {
        id: 'jobName',
        enableSorting: true,
        header: ({ column }) => (
          <Flex justifyContent="center" alignItems="center" gap="4px">
            <TableHeader>{t('pages.signings.tableHeaders.jobName')}</TableHeader>
            {getIconForSorting(column.getIsSorted())}
          </Flex>
        ),
        cell: info => (
          <Flex align="center" justify="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue() ?? info.row.original.name}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        enableSorting: true,
        header: ({ column }) => (
          <Flex justifyContent="center" alignItems="center" gap="4px">
            <TableHeader>{t('pages.signings.tableHeaders.createdDate')}</TableHeader>
            {getIconForSorting(column.getIsSorted())}
          </Flex>
        ),
        cell: info => (
          <Flex align="center" justify="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {formatDate(info.getValue())}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('submission.updatedAt', {
        id: 'submissionUpdatedAt',
        enableSorting: true,
        header: ({ column }) => (
          <Flex justifyContent="center" alignItems="center" gap="4px">
            <TableHeader>{t('pages.signings.tableHeaders.signedDate')}</TableHeader>
            {getIconForSorting(column.getIsSorted())}
          </Flex>
        ),
        cell: info => (
          <Flex align="center" justify="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue() &&
              [SubmissionSigningStatus.SUCCESS, SubmissionSigningStatus.REJECT, SubmissionSigningStatus.ERROR].includes(
                info.table.getRow(info.row.id).original.submission?.status as SubmissionSigningStatus,
              )
                ? formatDate(info.getValue() as Date)
                : '-'}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('submission.status', {
        id: 'status',
        enableSorting: true,
        header: ({ column }) => (
          <Flex justifyContent="center" alignItems="center" gap="4px">
            <TableHeader>{t('pages.signings.tableHeaders.status')}</TableHeader>
            {getIconForSorting(column.getIsSorted())}
          </Flex>
        ),
        cell: info => (
          <Flex align="center">
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={info.getValue() ? iconColorBySigningStatus[info.getValue()] : iconColorSubmissionDraft}
              as={info.getValue() ? iconBySubmissionSigningStatus[info.getValue()] : iconSubmissionDraft}
            />
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()
                ? t(signingStatusTranslateBySigningStatus[info.getValue()])
                : t(signingStatusTranslatedDraft)}
            </Text>
          </Flex>
        ),
      }),
    ],
    [colorMode],
  );

  const dataLength = signings.length;

  const timerId = useRef<any>(null);
  const fetchMore = useCallback(
    (containerElement?: HTMLDivElement | null) => {
      clearInterval(timerId.current);
      if (containerElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerElement;
        if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && dataLength < (totalCount || 0)) {
          timerId.current = setTimeout(() => fetchNextPage?.(), 100);
        }
      }
    },
    [fetchNextPage, isFetching, dataLength, totalCount],
  );

  useEffect(() => {
    fetchMore(tableContainerRef.current);
  }, [fetchMore]);

  const table = useReactTable({
    data: signings,
    columns,
    state: { sorting },
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    enableSorting: true,
    enableSortingRemoval: true,
    debugTable: false,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    debug: false,
    estimateSize: () => 48,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const tabSets: TabProps = {
    fontSize: '12px',
    fontWeight: 600,
    color: 'gray.400',
    p: '9px 16px 16px',
    _selected: {
      bg: sidebarBg,
      color: textColor,
      borderRadius: '10px 10px 0 0',
    },
    _focus: { outline: 'none' },
  };

  return (
    <Box>
      <Tabs index={tabIndex} onChange={onChangeTab} variant="enclosed" w="100%" padding="0 16px">
        <TabList overflowX="auto" overflowY="hidden">
          {signingTabKinds.map(tab => (
            <Tab key={tab} {...tabSets} minW="fit-content">
              {t(signingTabTranslateByKind[tab])}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Card flexDirection="column" w="100%" p="24px" overflowX={{ sm: 'hidden', lg: 'hidden' }} position="static">
        <Box overflowX="auto" overflowY="hidden" position="relative">
          <Table variant="simple">
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id} h="28px" display="flex" justifyContent="space-between" bg={sidebarBg}>
                  {headerGroup.headers.map(header => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        p="0 8px 4px 0px"
                        borderColor={borderColor}
                        cursor="pointer"
                        textTransform="capitalize"
                        onClick={header.column.getToggleSortingHandler()}
                        w={{ sm: '140px', md: '25%' }}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize="12px"
                          lineHeight="24px"
                          color="gray.400"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Box
              ref={tableContainerRef}
              onScroll={e => fetchMore(e.target as HTMLDivElement)}
              overflowY="auto"
              position="relative"
              h={{
                sm: 'calc(100vh - 264px - 28px)',
                md: 'calc(100vh - 224px - 28px)',
              }}
            >
              <Tbody h={`${rowVirtualizer.getTotalSize()}px`}>
                {rowVirtualizer.getVirtualItems().map(virtualRow => {
                  const row = rows[virtualRow.index];
                  return (
                    <Tr
                      key={row.id}
                      data-index={virtualRow.index}
                      ref={instance => rowVirtualizer.measureElement(instance)}
                      display="flex"
                      justifyContent="space-between"
                      position="absolute"
                      transform={`translateY(${virtualRow.start}px)`}
                      w="100%"
                      h="48px"
                      onClick={onClickSigning ? () => onClickSigning(row.original) : undefined}
                      cursor="pointer"
                      transition=".2s ease-in-out"
                      _hover={{ bg: rowHoverBg }}
                    >
                      {row.getVisibleCells().map(cell => (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          display="flex"
                          w={{ sm: '140px', md: '25%' }}
                          justifyContent="space-between"
                          borderColor="transparent"
                          p="0 8px 0 0"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>

              {isFetching && <Box>{t('loading')}</Box>}
            </Box>
          </Table>
        </Box>
      </Card>
    </Box>
  );
};
