import { useCallback } from 'react';
import { Box, Button, StyleProps, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { GoPlus } from 'react-icons/go';
import { t } from 'i18next';
import { SignerItem } from './signer-item';
import { SignerFormState } from '../signer/useSignerForm';
import { useSigners } from '../signer/use-signers';
import { Signing } from '../../../../api/signing';

export interface SignersWidgetProps extends StyleProps {
  isReadonly?: boolean;
  isInConfirmView?: boolean;
  isNewSignerCreating?: boolean;
  signing: Signing;

  onSignerCreateClick?: () => void;
  onSignerDeleted?: (id?: string) => void;
  onSignerCreated?: () => void;
}

export const SignersWidget = ({
  isReadonly,
  isInConfirmView,
  isNewSignerCreating,
  signing,
  onSignerCreateClick,
  onSignerDeleted,
  onSignerCreated,
  ...style
}: SignersWidgetProps) => {
  const toast = useToast();

  const { isLoading, signers, onCreate, onUpdate, onDelete } = useSigners({ signingId: signing.id });

  const handleCreate = useCallback(
    async (data: SignerFormState) => {
      try {
        await onCreate(data);
        onSignerCreated?.();
        toast({ title: t('createdSuccessfully'), status: 'success', duration: 1500 });
      } catch (error) {
        toast({
          title: t('error'),
          description: (error as { data: Error }).data?.message,
          status: 'error',
          duration: 5000,
        });
      }
    },
    [onCreate, onSignerCreated, toast],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await onDelete(id);
        onSignerDeleted?.(id);
      } catch (error) {
        toast({
          title: t('error'),
          description: (error as { data: Error }).data?.message,
          status: 'error',
          duration: 5000,
        });
      }
    },
    [onDelete, onSignerDeleted, toast],
  );

  const buttonBg = useColorModeValue('blue.900', '_brand.550');

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }

  return (
    <Card flexDirection="column" w="100%" p="24px" overflowX={{ sm: 'hidden', lg: 'hidden' }} {...style}>
      {signers?.map((signer, idx) => (
        <SignerItem
          isInConfirmView={isInConfirmView}
          key={signer.id}
          signer={signer}
          isDisabled={!!signing?.submission || isReadonly}
          onUpdate={onUpdate}
          onDelete={() => handleDelete(signer.id)}
          isShowBorderBottom={idx !== signers.length - 1 || isNewSignerCreating}
        />
      ))}

      {isNewSignerCreating && <SignerItem onCreate={handleCreate} onDelete={() => onSignerDeleted?.()} />}

      {!isReadonly && !signing?.submission && (
        <Button
          leftIcon={<GoPlus size={'20'} />}
          bg={buttonBg}
          disabled={isNewSignerCreating}
          color="white"
          borderRadius="10px"
          height="40px"
          w="fit-content"
          mt={!signers?.length && !isNewSignerCreating ? 0 : '16px'}
          _focus={{ outline: 'none' }}
          onClick={() => !isNewSignerCreating && onSignerCreateClick?.()}
        >
          {t('signer.addNewSigner')}
        </Button>
      )}
    </Card>
  );
};
