import { useCallback, useReducer } from 'react';
import { isEmailValid, isNifValid, isPhoneValid } from 'utils/validators';
import { IdDocumentType } from '../../../../api/signer';
import { SigningType } from '../../../../api/signing';

type SetInitialStateAction = { type: 'setInitialState'; value: Partial<SignerFormState> };
type SetNameAction = { type: 'setName'; value: string };
type SetEmailAction = { type: 'setEmail'; value: string };
type SetNifAction = { type: 'setNif'; value: string };
type SetPhoneAction = { type: 'setPhone'; value: string };
type SetIdDocumentType = { type: 'setIdDocumentType'; value: IdDocumentType };
type SetIdDocumentIssuerCountry = { type: 'setIdDocumentIssuerCountry'; value: string };
type SetIdDocumentNumber = { type: 'setIdDocumentNumber'; value: string };
type SetIdDocumentExpiryDate = { type: 'setIdDocumentExpiryDate'; value: Date };
type SetSigningType = { type: 'setSigningType'; value: SigningType };

export type SignerFormAction =
  | SetInitialStateAction
  | SetNameAction
  | SetEmailAction
  | SetNifAction
  | SetPhoneAction
  | SetIdDocumentType
  | SetIdDocumentIssuerCountry
  | SetIdDocumentNumber
  | SetIdDocumentExpiryDate
  | SetSigningType;

export type SignerFormState = {
  name: string;
  email: string;
  phone: string;
  idDocumentType: IdDocumentType;
  idDocumentIssuerCountry: string;
  idDocumentNumber: string;
  idDocumentExpiryDate: Date;
  signingType: SigningType;
  nif: string;
};

const signingInitialState: SignerFormState = {
  name: '',
  email: '',
  phone: '',
  idDocumentType: '' as IdDocumentType,
  idDocumentIssuerCountry: '',
  idDocumentNumber: '',
  idDocumentExpiryDate: '' as any,
  signingType: SigningType.SMS,
  nif: '',
};

export function useSignerForm() {
  const [form, dispatch] = useReducer((state: SignerFormState, action: SignerFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return {
          ...signingInitialState,
          ...state,
          ...action.value,
        };

      case 'setName': {
        return { ...state, name: action.value };
      }
      case 'setEmail': {
        return { ...state, email: action.value };
      }
      case 'setNif': {
        return { ...state, nif: action.value };
      }
      case 'setPhone': {
        return { ...state, phone: action.value };
      }
      case 'setIdDocumentType': {
        return { ...state, idDocumentType: action.value };
      }
      case 'setIdDocumentIssuerCountry': {
        return { ...state, idDocumentIssuerCountry: action.value };
      }
      case 'setIdDocumentNumber': {
        return { ...state, idDocumentNumber: action.value };
      }
      case 'setIdDocumentExpiryDate': {
        return { ...state, idDocumentExpiryDate: action.value };
      }
      case 'setSigningType': {
        return { ...state, signingType: action.value };
      }

      default:
        return state;
    }
  }, signingInitialState);

  const validate = useCallback<() => string[]>(() => {
    const errorMsgs = [];
    if (!form.name) {
      errorMsgs.push('nameIsRequired');
    }
    if (!form.email || !isEmailValid(form.email)) {
      errorMsgs.push('invalidEmail');
    }
    if (form.nif && !isNifValid(form.nif)) {
      errorMsgs.push('nifIsNotValid');
    }
    if (!form.phone) {
      errorMsgs.push('phoneIsRequired');
    }
    if (form.phone && !isPhoneValid(form.phone)) {
      errorMsgs.push('invalidPhone');
    }
    if (!form.idDocumentType) {
      errorMsgs.push('Id Document type is required');
    }
    if (!form.idDocumentIssuerCountry) {
      errorMsgs.push('Id Document issuer country is required');
    }
    if (!form.idDocumentNumber) {
      errorMsgs.push('Id document number is required');
    }
    if (!form.idDocumentExpiryDate) {
      errorMsgs.push('Id document expiry date is required');
    }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate };
}
