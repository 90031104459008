import {
  AccordionItem,
  AccordionButton,
  Box,
  Button,
  AccordionIcon,
  AccordionPanel,
  Text,
  useColorModeValue,
  Accordion,
  useToast,
  Hide,
} from '@chakra-ui/react';
import { LuTrash } from 'react-icons/lu';
import { SignerForm } from './signer-form';
import { Signer, SignerStatus, SignerUpdateDto } from 'api/signer';
import { useEffect, useState } from 'react';
import { SignerFormState, useSignerForm } from '../signer/useSignerForm';
import { Account, UserApprove, UserAttention, UserDelete } from '../../../../libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';
import { DebounceChangeHandleResult, useDebounceChanges } from '../utils/use-debounce-changes';
import { SignerSigningTypeSelect } from './signer-signing-type-select';
import { SigningType } from '../../../../api/signing';

export interface SignerItemProps {
  isInConfirmView?: boolean;
  isShowBorderBottom?: boolean;
  isDisabled?: boolean;
  signer?: Signer;
  onDelete: () => void | Promise<void>;
  onUpdate?: (dto: SignerUpdateDto) => Promise<void>;
  onCreate?: (values: SignerFormState) => Promise<void>;
}

export const SignerItem = (props: SignerItemProps) => {
  const { isInConfirmView, isShowBorderBottom, signer, isDisabled, onCreate, onUpdate, onDelete } = props;

  const { t } = useTranslation();
  const toast = useToast();

  const [isExpanded, setIsExpanded] = useState(false);

  const { form, dispatch, validate } = useSignerForm();
  const isUnsavedChanges = useDebounceChanges({
    form,
    excludeFields: ['signingType'],
    onChanges: async changes => {
      try {
        const validationMessages = validate();

        const preparedChanges = {
          name: form.name,
          email: form.email,
          phone: form.phone ? (form.phone[0] === '+' ? form.phone : '+' + form.phone) : undefined,
          nif: form.nif,
          signingType: form.signingType,
          idDocumentType: form.idDocumentType,
          idDocumentIssuerCountry: form.idDocumentIssuerCountry,
          idDocumentNumber: form.idDocumentNumber,
          idDocumentExpiryDate: form.idDocumentExpiryDate,
        };
        const optionalFields = ['nif'];

        const isFilledAllFields = Object.entries(preparedChanges)
          .filter(([k, _]) => !optionalFields.includes(k))
          .every(([_, v]) => !!v);

        if (!isFilledAllFields) {
          return DebounceChangeHandleResult.NOT_SUCCESS;
        }

        if (form.signingType === SigningType.SMS && !form.phone) {
          toast({
            title: t('validationError'),
            description: t('signer.form.phoneIsRequiredForSMSSigning'),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return DebounceChangeHandleResult.NOT_SUCCESS;
        }

        const isNeedToCreate = !signer && !validationMessages.length;
        const isNeedToUpdate = signer && !validationMessages.length;

        if (validationMessages.length && ((!signer && isFilledAllFields) || signer)) {
          toast({
            title: t('validationError'),
            description: validationMessages.map(i => t(i)).join(', \n'),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return DebounceChangeHandleResult.NOT_SUCCESS;
        }

        if (isFilledAllFields && isNeedToCreate && onCreate) {
          await onCreate(preparedChanges as SignerFormState);
          return DebounceChangeHandleResult.SUCCESS;
        }

        if (isNeedToUpdate && onUpdate) {
          await onUpdate({ id: signer.id, ...preparedChanges });
          return DebounceChangeHandleResult.SUCCESS;
        }

        return DebounceChangeHandleResult.SUCCESS;
      } catch (error) {
        toast({
          title: t('error'),
          description: (error as Error)?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return DebounceChangeHandleResult.NOT_SUCCESS;
      }
    },
  });

  useEffect(() => {
    setIsExpanded(!signer || new Date().getTime() - new Date(signer.createdAt).getTime() < 2000);
  }, []);

  useEffect(() => {
    dispatch({
      type: 'setInitialState',
      value: {
        name: signer?.name,
        email: signer?.email,
        phone: signer?.phone ?? '',
        nif: signer?.nif,
        idDocumentType: signer?.idDocumentType,
        idDocumentIssuerCountry: signer?.idDocumentIssuerCountry,
        idDocumentNumber: signer?.idDocumentNumber,
        idDocumentExpiryDate: signer?.idDocumentExpiryDate,
        signingType: signer?.signingType ?? SigningType.SMS,
      },
    });
  }, [dispatch, signer]);

  const bgColor = useColorModeValue('blue.50', 'navy.900');
  const borderColor = useColorModeValue('#E0E5F2', '#2D3748');
  const lightTextColor = useColorModeValue('#A3AED0', '#A3AED0');

  const textColor = useColorModeValue('_blue.900', 'white');
  const relationIconColor = useColorModeValue('_blue.900', 'white');

  return (
    <Accordion
      index={isExpanded ? 0 : -1}
      allowMultiple
      allowToggle
      borderLeft="none"
      borderRight="none"
      borderBottom={isShowBorderBottom ? '1px solid' : 'none'}
      borderColor={'gray.400'}
    >
      <AccordionItem border="none">
        <AccordionButton
          _expanded={{ bg: bgColor }}
          _focus={{ outline: 'none' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!signer || isUnsavedChanges ? (
            <UserAttention color={'red.400'} h={'20px'} w={'20px'} />
          ) : (
            <>
              {signer?.status === SignerStatus.INIT && <Account color={relationIconColor} h={'20px'} w={'20px'} />}
              {signer?.status === SignerStatus.SIGNED && <UserApprove color={'green.400'} h={'24px'} w={'24px'} />}
              {signer?.status === SignerStatus.REJECTED && <UserDelete color={'red.500'} h={'24px'} w={'24px'} />}
            </>
          )}

          <Box
            display="flex"
            flexDirection="column"
            as="span"
            flex="1"
            textAlign="left"
            ml="24px"
            mr="16px"
            minH={'45px'}
          >
            <Text fontSize="16px" fontWeight="bold" color={!signer || isUnsavedChanges ? 'red.400' : textColor}>
              {form.name || 'Name'}
            </Text>
            <Text fontSize="14px" fontWeight="500" color={lightTextColor}>
              {form.email || 'Email'}
            </Text>
          </Box>

          {isDisabled ? (
            <SignerSigningTypeSelect
              isDisabled={isDisabled}
              signingType={form.signingType}
              onChange={value => dispatch({ type: 'setSigningType', value })}
            />
          ) : (
            <Hide breakpoint="(max-width: 1500px)">
              <SignerSigningTypeSelect
                isDisabled={isDisabled}
                signingType={form.signingType}
                onChange={value => dispatch({ type: 'setSigningType', value })}
              />
            </Hide>
          )}

          {!isDisabled && (
            <Button
              leftIcon={<LuTrash />}
              colorScheme="gray"
              borderColor={borderColor}
              color={textColor}
              variant="outline"
              size="xs"
              minW={'fit-content'}
              mr="16px"
              onClick={e => {
                e.stopPropagation();
                onDelete();
              }}
            >
              {t('delete')}
            </Button>
          )}
          <AccordionIcon onClick={() => setIsExpanded(!isExpanded)} />
        </AccordionButton>

        <AccordionPanel pb={4} bgColor={bgColor} color={lightTextColor} pt={'4'}>
          <SignerForm isInConfirmView={isInConfirmView} form={form} dispatch={dispatch} isDisabled={isDisabled} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
