import { api } from './api';
import { SigningType } from './signing';

export enum SignerStatus {
  INIT = 'INIT',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
}

export enum IdDocumentType {
  CC = 'CC',
  PASSPORT = 'PASSPORT',
  RP = 'RP',
}

export interface Signer {
  id: string;
  name: string;
  email: string;
  phone: string;
  idDocumentType: IdDocumentType;
  idDocumentIssuerCountry: string;
  idDocumentNumber: string;
  idDocumentExpiryDate: Date;
  signingType: SigningType;
  nif?: string;
  status: SignerStatus;
  signingId: string;
  organizationId?: string;

  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
}

export interface SignerCreateDto {
  name: string;
  email: string;
  phone: string;
  idDocumentType: IdDocumentType;
  idDocumentIssuerCountry: string;
  idDocumentNumber: string;
  idDocumentExpiryDate: Date;
  signingType: SigningType;
  nif?: string;
  signingId: string;
}

export interface SignerUpdateDto {
  id: string;
  name?: string;
  email?: string;
  phone?: string;
  idDocumentType?: IdDocumentType;
  idDocumentIssuerCountry?: string;
  idDocumentNumber?: string;
  idDocumentExpiryDate?: Date;
  signingType?: SigningType;
  nif?: string;
}

const signerApi = api.injectEndpoints({
  endpoints: build => ({
    getSigners: build.query<Signer[], string>({
      query: signingId => `signer?signingId=${signingId}`,
    }),
    createSigner: build.mutation<Signer, SignerCreateDto>({
      query: data => ({ url: '/signer', method: 'POST', body: data }),
      invalidatesTags: [{ type: 'Signer', id: 'LIST' }],
    }),
    getSigner: build.query<Signer, string>({
      query: id => `signer/${id}`,
      providesTags: (result, error, id) => [{ type: 'Signer', id }],
    }),
    updateSigner: build.mutation<Signer, SignerUpdateDto>({
      query: data => ({ url: `/signer`, method: 'PUT', body: data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Signer', id }],
    }),
    deleteSigner: build.mutation<{ success: boolean; id: string }, string>({
      query: id => ({ url: `/signer/${id}`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'Signer', id }],
    }),
  }),
});

export const {
  useGetSignersQuery,
  useLazyGetSignerQuery,
  useLazyGetSignersQuery,
  useGetSignerQuery,
  useCreateSignerMutation,
  useUpdateSignerMutation,
  useDeleteSignerMutation,
  endpoints: SignerEndpoints,
} = signerApi;

export { signerApi };
